// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { push } from 'connected-react-router'
import { useDispatch } from 'react-redux'
import type { Node } from 'react'

import { getFormattedDate } from '../../utils/utils'
import NewTableBody from '../NewTable/NewTableBody'
import NewTableBodyRow from '../NewTable/NewTableBodyRow'
import NewTableBodyCell from '../NewTable/NewTableBodyCell'
import TableCellUsers from '../TableCellUsers'
import SelectCustom from '../Select/SelectCustom'
import Checkbox from '../Checkbox'

import styles from './ConnectedCompanies.module.scss'

type Props = {
  companies: Array<Object>,
  onDelete: string => void,
  outbound: boolean,
  permissions: Object,
  setModal: Function,
}

const ConnectedCompaniesTableBody = (props: Props): Node => {
  const { companies, outbound, permissions, selectedItems } = props
  const { t } = useTranslation('IdwellToIdwell')
  const dispatch = useDispatch()

  const handleOpen = id => () => {
    const connectionType = outbound ? 'outbound' : 'inbound'
    dispatch(push(`/settings/${connectionType}/${id}`))
  }

  let options = []

  if (permissions?.can_delete) {
    options.push(
      options.push({
        label: t('DeleteCompany'),
        value: 'remove',
        handler: uuid => props.onDelete(uuid),
        icon: 'remove',
      })
    )
  }

  return (
    <NewTableBody>
      {companies.map(company => {
        const { uuid, name, inbound_user_obj, created, updated, status } =
          company

        const dateField = outbound ? updated : created
        const formattedDate = dateField ? getFormattedDate(dateField) : '-'

        return (
          <NewTableBodyRow key={uuid} onClick={handleOpen(uuid)}>
            <NewTableBodyCell title={name}>
              <Checkbox
                style={{ marginLeft: '10px' }}
                checked={selectedItems.includes(uuid)}
                onChange={() => props.onSelect(uuid)}
              />
            </NewTableBodyCell>
            {outbound ? (
              <NewTableBodyCell
                className={status === 'valid' ? styles.valid : styles.invalid}
                title={t(`Status.${status}`)}
              />
            ) : (
              <>
                <NewTableBodyCell title={'Workflow'} />
                <NewTableBodyCell className={styles.usersCell}>
                  {inbound_user_obj && (
                    <TableCellUsers items={[inbound_user_obj]} />
                  )}
                </NewTableBodyCell>
              </>
            )}
            <NewTableBodyCell title={formattedDate}>
              {options.length > 0 && (
                <div className='table-extra table-extra--settings'>
                  <SelectCustom
                    options={options}
                    onChange={({ handler }) => handler(uuid)}
                  />
                </div>
              )}
            </NewTableBodyCell>
          </NewTableBodyRow>
        )
      })}
    </NewTableBody>
  )
}

export default ConnectedCompaniesTableBody
