// @flow

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Autosuggest from 'react-autosuggest'
import { compose } from 'redux'
import { uniqBy, get, omit } from 'lodash-es'
import TagsInput from 'react-tagsinput'
import { DropTarget } from 'react-dnd'
import MailSearchRecipient, { DragItemType } from './MailSearchRecipient'

import type { Dispatch } from 'redux'

import * as actions from './MailSearch.actionTypes'
import MailSearchSuggestion from './MailSearchSuggestion'
import { getUserName, hasEmail } from '../../utils/utils'
import styles from './MailSearch.module.scss'

import Icon from '../../components/Icon'
import { EMAIL_REG_EXP } from '../../constants'
import * as classnames from 'classnames'
import { getCurrentFilters } from '../../utils/routing'
import { getLocation } from '../../utils/commonSelectors'
import { getUsersForMassEmail } from '../../core/api/api.profile'
import { formatFilters } from './MailSearch.constants'

const PAGE_SIZE = 40
const TAGS_PREVIEW_COUNT = 3

type State = {
  isValidInput: boolean,
}

type Props = {
  addChatMembers: boolean,
  currentUserId: number,
  data: Object,
  dispatch: Dispatch<Object>,
  fromList?: boolean,
  id: string,
  onChange: Array<Object>,
  onValidate: boolean => void,
  params: Object,
  placeholder: string,
  t: string => string,
  uuid?: string,
  withProviders: boolean,
}

function getSuggestionValue(suggestion) {
  return <MailSearchSuggestion suggestion={suggestion} />
}

function validateTag(tag) {
  if (tag.profile || tag.variable) {
    return true
  }

  return EMAIL_REG_EXP.test(tag.name)
}

const dropTarget = {
  drop(props, monitor) {
    const { group: currentGroup } = monitor.getItem()
    const { group: toGroup, onEndDragBlock } = props

    if (currentGroup !== toGroup) {
      onEndDragBlock({ ...monitor.getItem(), toGroup })
    }
  },
}

class MailSearch extends Component<Props, State> {
  static defaultProps = {
    params: {},
  }

  static createTagFromProfile(profile) {
    const userName = getUserName(profile)
    const email = get(profile, 'email')
    const emailString = email && email !== userName ? ` <${email}>` : ''

    return {
      name: `${userName}${emailString}`,
      profile,
    }
  }

  state = {
    isValidInput: true,
    showAllTo: false,
    page: 1,
  }

  componentDidMount() {
    const { id, selectedUsers, location } = this.props

    if (Array.isArray(selectedUsers)) {
      this.props.onChange(
        selectedUsers.map(u => MailSearch.createTagFromProfile(u))
      )
    }

    const filters = getCurrentFilters(location)

    if (filters?.from === 'redesign') {
      const params = {
        filters: { ...omit(formatFilters(filters), ['send', 'from', 'sub']) },
      }
      getUsersForMassEmail(params).then(data => {
        this.props.onChange(
          data.items.map(u => MailSearch.createTagFromProfile(u))
        )
      })
    }

    const container = document.querySelector(
      '.react-autosuggest__suggestions-container'
    )

    if (container) {
      container.addEventListener('scroll', this.onScrollSuggestions)
    }

    this.props.dispatch({
      type: actions.MAIL_SEARCH_INIT,
      key: id,
    })
  }

  componentWillUnmount() {
    const container = document.querySelector(
      '.react-autosuggest__suggestions-container'
    )

    if (container) {
      container.removeEventListener('scroll', this.onScrollSuggestions)
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.managers.length > this.props.managers.length &&
      this.props.managers.length < 3 &&
      this.state.showAllTo
    ) {
      this.onClickShowAllTo()
    }

    if (!this.state.showAllTo && this.props.managers.some(m => m.blacklist)) {
      this.onClickShowAllTo()
    }
  }

  onSuggestionsClearRequested = () => {
    this.props.dispatch({
      type: actions.MAIL_SEARCH_CLEAR,
      key: this.props.id,
    })
    this.setState({ page: 1 })
  }

  onScrollSuggestions = e => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight

    if (bottom) {
      const { value, isLoading, page_count } = this.getData()

      if (this.state.page < page_count && !isLoading) {
        this.setState(prevState => ({ page: prevState.page + 1 }))
        this.loadSuggestions({ value })
      }
    }
  }

  getData = () =>
    this.props.data[this.props.id] || {
      value: '',
      suggestions: [],
      isLoading: false,
      page_count: 1,
    }

  renderInputComponent = inputProps => {
    const css = inputProps.wrapperCss
    delete inputProps.wrapperCss

    return (
      <span className={css}>
        <input {...inputProps} />
        {this.props.fromList && (
          <Icon id='magnifier' className='react-tagsinput-input-icon' />
        )}
      </span>
    )
  }

  onChangeSuggestionInput = (e, { newValue, method }) => {
    if (method !== 'enter') {
      this.setState({ isValidInput: true })
    }

    this.props.dispatch({
      type: actions.MAIL_SEARCH_UPDATE_VALUE,
      value: newValue,
      key: this.props.id,
    })

    this.props.onChangeInput && this.props.onChangeInput()

    if (!newValue.length) {
      this.props.onValidate && this.props.onValidate(true)
    }
  }

  loadSuggestions = ({ value, reason }) => {
    if (value.length < 3) {
      return
    }

    const { withProviders, addChatMembers, managers } = this.props
    const excludeManagers = managers.map(m => m.profile?.email).filter(Boolean)

    const isInputChanged = reason === 'input-changed'

    const params = {
      fullname_or_email: value.slice(0, 253),
      page_size: PAGE_SIZE,
      page: isInputChanged ? 1 : this.state.page,
      exclude:
        excludeManagers.length > 0 ? excludeManagers.join(',') : undefined,
    }

    this.props.dispatch({
      type: actions.MAIL_SEARCH_LOAD,
      params,
      value,
      addChatMembers,
      withProviders,
      key: this.props.id,
    })
  }

  checkForIgnored(profile) {
    const { managers } = this.props

    if (!managers.length) {
      return false
    }

    const alreadyHaveManager = managers.find(m => m.id === profile.id)

    return !!alreadyHaveManager
  }

  checkForAlreadyHaveTag(tag) {
    const { ignoredUsers } = this.getData()

    if (!ignoredUsers || !ignoredUsers.length) {
      return false
    }

    const isEqual = tag.profile
      ? manager => manager.id === tag.profile.id
      : manager => manager.name === tag.name

    const alreadyHaveManager = ignoredUsers.find(isEqual)

    return !!alreadyHaveManager
  }

  handleManagerChange = (tags, addedTags) => {
    const tag = addedTags[0]
    const tagsList = tag.name.split(/(?:;| )+/)

    if (tagsList.length <= 1 || tag.profile) {
      const isValidInput = validateTag(tag)
      this.props.onValidate && this.props.onValidate(isValidInput)
      this.setState({ isValidInput })

      if (!isValidInput || this.checkForAlreadyHaveTag(tag)) return
    } else {
      if (!tagsList.filter(Boolean).length) {
        return
      }

      let currentTags = tagsList.reduce((list, email) => {
        const currentTag = { name: email.trim() }

        if (validateTag(currentTag)) {
          list.push(currentTag)
        }

        return list
      }, [])

      tags = [
        ...tags.filter(current => current.name !== tag.name),
        ...currentTags,
      ]
    }

    const managersTags = uniqBy(tags, item =>
      item.profile ? item.profile.id : item.name
    ).filter(t => !t.variable)

    this.props.dispatch({
      type: actions.MAIL_SEARCH_UPDATE_VALUE,
      value: '',
      key: this.props.id,
    })

    this.props.onChange(managersTags)

    if (this.props.onChangeVariables) {
      this.props.onChangeVariables(tags.filter(t => t.variable))
    }
  }

  autocompleteRenderInput = ({ addTag, ...props }) => {
    const handleOnChange = (e, { newValue, method }) => {
      if (method === 'enter') {
        e.preventDefault()
      } else {
        const valueInput = newValue.length ? newValue : ''
        this.setState({ page: 1 })
        this.onChangeSuggestionInput(e, { newValue: valueInput, method })
      }
    }
    const { placeholder, t, uuid } = this.props
    const { value, suggestions } = this.getData()

    const currentUserIndex = suggestions.findIndex(({ owner, id }) => {
      const currentUserId = this.props.currentUserId

      return owner === currentUserId || id === currentUserId
    })

    const filteredSuggestions =
      currentUserIndex === -1
        ? suggestions
        : [
            ...suggestions.slice(0, currentUserIndex),
            ...suggestions.slice(currentUserIndex + 1),
          ]

    const inputProps = {
      placeholder,
      value,
      autoFocus: uuid === 'create',
    }

    const { isValidInput } = this.state

    if (!isValidInput) {
      inputProps.title = t('EnterCorrectEmail')
      inputProps.wrapperCss = 'input-error'
    }

    return (
      <Autosuggest
        ref={props.ref}
        suggestions={filteredSuggestions}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={getSuggestionValue}
        inputProps={{
          ...props,
          ...inputProps,
          onChange: handleOnChange,
        }}
        renderInputComponent={this.renderInputComponent}
        onSuggestionSelected={(e, { suggestion }) => {
          if (this.checkForIgnored(suggestion)) {
            return false
          }

          addTag(MailSearch.createTagFromProfile(suggestion))
        }}
        onSuggestionsFetchRequested={this.loadSuggestions}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
      />
    )
  }

  onClickShowAllTo = () => {
    this.setState(state => ({
      showAllTo: !state.showAllTo,
    }))
  }

  getDisplayName = profile => {
    let { showAllTo } = this.state
    let emailClass
    let name = getUserName(profile)

    if (this.props.managers.length > 3) {
      emailClass = classnames({
        [styles.secondText]: showAllTo && name,
        [styles.displayNone]: name && !showAllTo,
      })
    } else {
      emailClass = classnames({
        [styles.secondText]: name,
      })
    }

    return (
      <>
        {name}
        <span className={emailClass}>{` <${profile?.email}>`}</span>
      </>
    )
  }
  defaultRenderTag = props => {
    if (this.props.fromList) {
      return ''
    }

    let { t, managers, variables = [], group, toGroup } = this.props

    let {
      tag,
      key,
      disabled,
      onRemove,
      className,
      classNameRemove,
      getTagDisplayValue,
      ...other
    } = props

    if (!tag.variable) {
      const noEmail = !hasEmail(tag)
      const toPrinter = tag.profile?.printer
      const blacklist = tag.blacklist

      if (noEmail) {
        className += ' noemail'
      }

      if (toPrinter) {
        className += ' printer'
      }

      if (blacklist) {
        className += ' blacklist'
      }
    }

    if (!this.state.showAllTo) {
      if (key === TAGS_PREVIEW_COUNT) {
        return (
          <span className={styles.more} onClick={() => this.onClickShowAllTo()}>
            +{managers.length + variables.length - TAGS_PREVIEW_COUNT}{' '}
            {t('more')}
          </span>
        )
      } else if (key > 3) {
        return null
      }
    }

    const classNameSpan = classnames(className, styles.tag)

    return (
      <MailSearchRecipient
        key={key}
        name={tag.name}
        variable={tag.variable}
        group={group}
        toGroup={toGroup}
      >
        <span className={classNameSpan} {...other}>
          <span>
            {tag.profile
              ? this.getDisplayName(tag.profile)
              : getTagDisplayValue(tag)}
          </span>
          {!disabled && (
            <a className={classNameRemove} onClick={() => onRemove(key)} />
          )}
        </span>
      </MailSearchRecipient>
    )
  }

  render() {
    const {
      placeholder,
      managers,
      connectDropTarget,
      fromList,
      variables = [],
    } = this.props

    const { value } = this.getData()
    const tagsinputClass = classnames('react-tagsinput mail', styles.tagInput)

    return connectDropTarget(
      <div className={fromList ? '' : styles.tagInputWrap}>
        <TagsInput
          addOnBlur
          renderTag={this.defaultRenderTag}
          placeholder={placeholder}
          value={[...managers, ...variables]}
          addKeys={[9, 13]}
          className={tagsinputClass}
          tagDisplayProp='name'
          style={{ border: 'none' }}
          inputValue={value}
          renderInput={this.autocompleteRenderInput}
          onChange={this.handleManagerChange}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.mailSearch,
  location: getLocation(state),
})

export default compose(
  withTranslation('Mail'),
  DropTarget(DragItemType, dropTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isMoving: monitor.canDrop(),
  })),
  connect(mapStateToProps)
)(MailSearch)
