// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { Row } from './Block'
import RequestProvidersEditingPopup from './RequestProvidersEditingPopup'
import ValueItem from './Select/ValueItem'
import { CLEAR_EDIT_FROM_WIDGET } from '../containers/RequestView/RequestView.constants'

import { MAIL_SEARCH_SELECT_PROVIDERS } from './MailSearch/MailSearch.actionTypes'

import styles3 from './RequestProviders.module.scss'

const RequestProviders = props => {
  const { contractors, softArchived, buildingId, automate, outbound } = props
  const [editing, setEditing] = useState(false)

  const { editFromWidget, contractor } = useSelector(state => state.requestView)
  const dispatch = useDispatch()

  const { t } = useTranslation('Request')

  const handleEdit = () => setEditing(true)
  const handleClose = () => {
    dispatch({ type: CLEAR_EDIT_FROM_WIDGET })
    setEditing(false)
  }

  useEffect(() => {
    dispatch({
      type: MAIL_SEARCH_SELECT_PROVIDERS,
      selectedProviders: contractors.map(c => ({
        id: c.id,
        fullname: c.name,
        email: c.email,
      })),
    })
  }, [contractors])

  useEffect(() => {
    if (editFromWidget) {
      setEditing(true)
    }
  }, [editFromWidget])

  const rowProps = Object.assign(
    {},
    softArchived || outbound ? undefined : { onEdit: handleEdit }
  )

  return (
    <>
      <Row title={t('RequestProvidersTitle')} editing={editing} {...rowProps}>
        {contractors.length ? (
          <div className={styles3.listWrapper} style={{ border: 'none' }}>
            {contractors.map(c => (
              <ValueItem
                inline
                profile
                round
                isLink={!outbound}
                avatar={c.avatar}
                pathName='contractor'
                text={c.name}
                key={c.id}
                valueId={c.id}
                id={c.id}
                className={styles3.valueItem}
              />
            ))}
          </div>
        ) : (
          <span className='bar__submit-label_light bar__submit-label no-manager-assignet-label'>
            {t('NoProviders')}
          </span>
        )}
      </Row>
      {editing && (
        <RequestProvidersEditingPopup
          automate={automate}
          editing={editing}
          handleClose={handleClose}
          contractors={contractors}
          contractor={contractor}
          buildingId={buildingId}
          onChange={props.onChange}
          onChangeContractors={props.onChangeContractors}
        />
      )}
    </>
  )
}

export default RequestProviders
