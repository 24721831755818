// @flow

import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Scrollbar from 'react-scrollbars-custom'
import { isArray } from 'lodash-es'

import Loader from '../../Loader'
import TrackY from '../../NewFilterAsync/TrackY'
import ThumbY from '../../NewFilterAsync/ThumbY'
import { FOLDER_TYPE } from '../Files.constants'
import FilesIcon from '../FilesIcon'
import {
  getList,
  renameFile,
  changeDirectory,
} from '../../../core/api/api.file'
import { useOverflow } from '../../../hooks'
import { ALL_PAGES } from '../../../constants'

import styles from '../../NewFilterAsync/NewFilterAsyncMenuList/NewFilterAsyncMenuList.module.scss'
import styles3 from '../../RequestProviders.module.scss'
import styles2 from './ReplacingPopup.module.scss'

const ReplacingPopup = props => {
  const {
    title,
    buttonTitle,
    folderId,
    type,
    id,
    outbound,
    selected,
    isMulti,
  } = props
  const [loading, setLoading] = useState(false)
  const [folder, setFolder] = useState(folderId)
  const [files, setFiles] = useState([])
  const [parent, setParent] = useState(null)
  const [name, setName] = useState(null)
  const [isPersistent, sePersistent] = useState(null)

  const { t } = useTranslation('Files')

  useOverflow()

  useEffect(() => {
    setLoading(true)

    if (folder) {
      getList({ outbound, parent: folder, page_size: ALL_PAGES })
        .then(data => {
          setFiles(
            data.objects.filter(
              f => f.owner && f.type === FOLDER_TYPE && f.permissions?.can_write
            )
          )
          setParent(data.parent.parent)
          setName(data.parent.name)
          sePersistent(data.parent.is_persistent)
        })
        .finally(() => setLoading(false))
    }
  }, [folder])

  const handleClick = id => () => setFolder(id)

  const handleBack = () => {
    if (parent) {
      setFolder(parent)
    }
  }

  const handleSave = () => {
    if (isArray(selected) && isMulti) {
      Promise.all(
        selected.map(file => {
          const { type, id: fileId } = file

          let api = renameFile

          if (type === FOLDER_TYPE) {
            api = changeDirectory
          }

          return api(fileId, { parent: folder, outbound })
        })
      )
        .then(() => props.closePopup())
        .catch(error => props.setError(error))
        .finally(() => {
          props.setLoading(true)
        })

      return
    }

    let api = renameFile

    if (type === FOLDER_TYPE) {
      api = changeDirectory
    }

    api(id, { parent: folder, outbound })
      .then(() => props.closePopup())
      .catch(error => props.setError(error))
      .finally(() => {
        props.setLoading(true)
      })
  }

  return (
    <>
      <div className='modal__title'>{t(title)}</div>
      <button
        className='modal__close'
        type='button'
        onClick={props.closePopup}
      />
      <div className='modal__body'>
        <div className={styles2.navigation}>
          <button
            disabled={!parent || loading}
            style={{
              marginLeft: 0,
              borderTop: 0,
              borderBottom: 0,
              borderLeft: 0,
            }}
            type='button'
            className='messages__header-back button-back button-back--default-2 button-back--large'
            onClick={handleBack}
          />
          <span>{isPersistent ? t(name) : name}</span>
        </div>
        {!loading ? (
          <div className={styles3.listWrapper}>
            <Scrollbar
              noScrollX
              style={{ height: 175 }}
              trackYProps={{
                renderer: TrackY,
              }}
              thumbYProps={{
                renderer: ThumbY,
              }}
            >
              <ul className={styles.list}>
                {files.map(file => (
                  <li
                    key={file.id}
                    className={styles2.listItem}
                    onClick={handleClick(file.id)}
                  >
                    <FilesIcon file={file} />
                    <span>{file.is_persistent ? t(file.name) : file.name}</span>
                  </li>
                ))}
              </ul>
            </Scrollbar>
          </div>
        ) : (
          <div
            style={{
              height: '175px',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
            className={styles3.listWrapper}
          >
            <Loader type='medium' text={false} />
          </div>
        )}
      </div>
      <div className='modal__submit'>
        <button
          className='button button--large button--success'
          type='button'
          disabled={loading || parseInt(folder) === parseInt(folderId)}
          onClick={handleSave}
        >
          {t(buttonTitle)}
        </button>
        <button
          disabled={loading}
          className='button button--large button--danger-3'
          type='button'
          onClick={props.closePopup}
        >
          {t('Cancel')}
        </button>
      </div>
    </>
  )
}

export default ReplacingPopup
